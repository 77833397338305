import axios from 'axios'

// dev env
// export const back = axios.create({
//     baseURL: "http://0.0.0.0:5000/api/",
//     timeout: 5000,
//     headers: {
//         "Acces-Control-Allow-Origin": "*",
//         "Content-Type": "application/json"
//     }
// })

// prod env
export const back = axios.create({
    baseURL: "https://back-360l-cz-lb5a3zm7ta-ew.a.run.app/api/",
    timeout: 5000,
    headers: {
        "Acces-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    }
})