<template>
  <div class="container">
    <v-card class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <!-- <v-card-title>Form</v-card-title> -->

      <v-img
        class="pt-10"
        height="80"
        src="https://www.abtasty.com/wp-content/uploads/logo-abtasty.png"
      ></v-img>

      <v-card-text>
        <v-text-field
          v-model="firstName"
          :rules="firstNameCodeRules"
          label="First Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="lastName"
          :rules="lastNameCodeRules"
          label="Last Name"
          required
        ></v-text-field>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            :items="languageItem"
            label="Language"
            v-model="language"
            outlined
          ></v-select>
        </v-col>

        <v-btn
          :disabled="!valid"
          color="#217981"
          class="mr-4 white--text"
          @click="validate"
        >
          Validate
        </v-btn>
        <div class="text-danger">
          {{ message }}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
    </v-card>
  </div>
</template>

<script>
import Router from "../router";
import { back } from "../axios"
export default {
  name: "HomeAx",
  data: () => ({
    valid: true,
    firstNameCodeRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 2) || "First Name must be valid",
    ],
    lastNameCodeRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 2) || "Last Name must be valid",
    ],
    email: "",
    firstName: "",
    lastName: "",
    language: "",
    verificationCode: "",
    message: "",
    languageItem: ["en", "fr"],
  }),

  methods: {
    async validate() {
      // Check if email and verification code is ok
      var data = JSON.stringify({
        email: this.$route.params.email,
        verificationCode: this.$route.params.verificationCode,
        firstName: this.firstName,
        lastName: this.lastName,
        language: this.language,
      });

      await back.post('/accountCreation', data)
        .then(res => {
          if (res.status === 200) {
            Router.push("/accountCreated")
          }
          this.message = 'Ton code est bon'
        })
        .catch(err=>{
          console.log(err)
          this.message = "Please retry"
        })
    },
  },
};
</script>
