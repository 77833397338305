<template>
  <div class="container">
    <v-card class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title>Code de vérification</v-card-title>

      <v-img
        height="80"
        src="https://www.abtasty.com/wp-content/uploads/logo-abtasty.png"
      ></v-img>

      <v-card-text>
        <v-text-field
          v-model="verificationCode"
          :rules="verificationCodeRules"
          label="Verification Code"
          required
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          color="#217981"
          class="mr-4 white--text"
          @click="validate"
        >
          Validate
        </v-btn>
        Timer :
        {{ new Date(countDown * 1000).toISOString().substring(14, 19) }}
        <br />
        <br />
        <div class="text-danger">
          {{ message }}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
    </v-card>
  </div>
</template>

<script>
// import axios from "axios";
import Router from "../router";
import { back } from "../axios"
export default {
  name: "HomeAx",
  data: () => ({
    valid: true,
    verificationCode: "",
    verificationCodeRules: [
      (v) => !!v || "Verification Code is required",
      (v) => (v && v.length <= 10) || "Verification Code must be valid",
    ],
    message: "",
    countDown: 900,
  }),
  mounted() {
    this.countDownTimer();
  },

  methods: {
    async validate() {
      let self = this;
      // Check if email is ok
      const email = this.$route.params.email;
      const verificationCode = this.verificationCode;
      var data = JSON.stringify({
        email: email,
        verificationCode: this.verificationCode,
      });
      await back.post('/verificationCode', data)
        .then((res) => {
          console.log(JSON.stringify(res.data));
          Router.push({
            name: 'form',
            params: {
              email: email,
              verificationCode: verificationCode
            }
          })
          this.message = "Ton code et bon";
        })
        .catch(function (error) {
          console.log(error);
          self.message = "The verification code is not correct";
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>