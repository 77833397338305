import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import VerificationCode from '../views/verificationCode.vue'
import Form from '../views/form.vue'
import AccountCreated from '../views/accountCreated.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/verificationCode/:email',
    name: 'verificationCode',
    component: VerificationCode
  },
  {
    path: '/form/:email/:verificationCode',
    name: 'form',
    component: Form
  },
  {
    path: '/accountCreated',
    name: 'accountCreated',
    component: AccountCreated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
