<template>
  <div>
    <v-card class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title><p class=".text-white-50">Connection</p></v-card-title>
      <v-img
        height="80"
        src="https://www.abtasty.com/wp-content/uploads/logo-abtasty.png"
      ></v-img>

      <v-card-text>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
        <v-btn
          :disabled="!valid"
          color="#217981"
          class="mr-4 white--text"
          @click="validate"
        >
          Validate
        </v-btn>
        <br>
        <br>
        <div class="text-danger">
          {{message}}
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
    </v-card>
  </div>
</template>

<script>
import Router from "../router";
import { back } from '../axios'
export default {
  name: "HomeAx",
  data: () => ({
    valid: true,
    email: "",
    message: '',
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),

  methods: {
    async validate() {

      await back.post("/accountInitialisation", {'email': this.email})
        .then(res => {
          console.log(res.status)
          if (res.status === 200) {
            Router.push({
              name: "verificationCode",
              params: {
                email: this.email,
              },
            })
          }
        }).catch(err => {
          console.log(err)
          this.message = "This mail is uncorrect, please retry"
        })
    },
  },
};
</script>
<style scoped>
.btn-primary {
    @include button-variant($mynewcolor, darken($mynewcolor, 7.5%), darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
}

.btn-outline-primary {
    @include button-outline-variant($mynewcolor, #222222, lighten($mynewcolor,5%), $mynewcolor);
}
</style>